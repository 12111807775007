<template>
    <div class="star-win">
        <div class="star-win-main">
            <div class="star-win-item" v-for="(item,index) in list" v-if="index != 0 && index<4">
                <div class="star-win-head">
                    <img :src="item.player_img"/>
                </div>
                <div class="star-win-name">
                    {{ item.player_name }}
                </div>
                <div class="star-win-hospital_name">
                    {{ item.player_hospital_name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                title: '',
                list: []
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                this.$axios.post(this.apiUrl.show_match_api).then(res => {
                    this.list = res.data
                    this.title = res.msg
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    .star-win{
        /*min-height: 798.6px;*/
        height: 100vh;
        min-height: 1080px;
        background: url("https://image.ysxxlm.cn/ysxxlm/pc/static/img/baiji-2.png?v=1") top center no-repeat;
        background-size: 100% 100%;
        .star-win-main{
            text-align: center;
            padding-top: 22%;
            .star-win-item{
                display: inline-block;
                margin: 0 40px;
                text-align: center;
                color: #9d004d;
                font-weight: bold;
                .star-win-head{
                    width: 220px;
                    display: inline-block;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                .star-win-name{
                    font-size: 30px;
                }
                .star-win-hospital_name{
                    font-size: 30px;
                }
            }
        }
    }
</style>

